$.lui("part", ".part_form_booking", function(selector) {
    selector.each(function(){
        let selector = $(this);

        if (!selector.hasClass("is--loaded")) {
            selector.addClass("is--loaded");

            (function fn_datepicker() {
                let checkin = selector.find('[data-form-datepicker="arrival"]');
                let checkout = selector.find('[data-form-datepicker="departure"]');

                if (checkin.length && checkout.length) {
                    let minDate = new Date();
                    let maxDate = new Date();
                    maxDate.setFullYear(new Date().getFullYear() + 1);

                    let lang_url;

                    if (lang === "ru") {
                        lang_url = cdnjs.datepicker_lang;
                    } else {
                        lang_url = cdnjs.datepicker_lang.replace(".cs.", "." + lang + ".")
                    }
                    $.importScript(cdnjs.datepicker, function(){
                        $.importScript(lang_url, function () {

                            $(checkin).each(function(){
                                let checkin = $(this);
                                let checkout = checkin.closest(".part_form_booking").find('[data-form-datepicker="departure"]');
                                let datepicker_in = checkin.find("[data-arrival]").datepicker({
                                    language: lang,
                                    position: "bottom center",
                                    minDate: minDate,
                                    maxDate: maxDate,
                                    startDate: new Date($("[data-arrival]").val()),
                                    autoClose: true,
                                    dateFormat: 'yyyy-mm-dd',
                                    onShow: function (inst, animationComplete) {
                                        doc.find(".datepickers-container").addClass("state--active").children(".datepicker").attr("data-title", checkin.data("title"));

                                        if(!animationComplete){
                                            let iFits = false;
                                            // Loop through a few possible position and see which one fits
                                            $.each(['bottom center', 'top center'], function (i, pos) {
                                                if (!iFits) {
                                                    inst.update('position', pos);
                                                    let fits = inViewport(inst.$datepicker[0]);
                                                    if (fits.all) {
                                                        iFits = true;
                                                    }
                                                }
                                            });
                                        }
                                    },
                                    onHide: function () {
                                        doc.find(".datepickers-container").removeClass("state--active");
                                    },
                                    onSelect: function (fd, d) {
                                        let month = $.fn.datepicker.language[lang]['months'][d.getMonth()];
                                        checkin.find("[data-day]").text(d.getDate());
                                        checkin.find("[data-month]").text(month);

                                        let dateOut = new Date(d.getTime());
                                        dateOut.setDate(dateOut.getDate() + 1);
                                        checkout.find("[data-departure]").datepicker().data('datepicker').selectDate(dateOut);
                                        
                                        checkout.find("[data-departure]").datepicker().data('datepicker').update({
                                            minDate: dateOut
                                        });
                                    }
                                }).data('datepicker');
                                checkin.on("click", function () {
                                    datepicker_in.show();
                                });
                            });

                            $(checkout).each(function(){
                                let checkout = $(this);
                                
                                let datepicker_out = checkout.find("[data-departure]").datepicker({
                                    language: lang,
                                    position: "bottom center",
                                    startDate: new Date($("[data-departure]").val()),
                                    autoClose: true,
                                    dateFormat: 'yyyy-mm-dd',
                                    minDate: minDate,
                                    onShow: function (inst, animationComplete) {
                                        doc.find(".datepickers-container").addClass("state--active").children(".datepicker").attr("data-title", checkout.data("title"));

                                        if(!animationComplete){
                                            let iFits = false;
                                            // Loop through a few possible position and see which one fits
                                            $.each(['bottom center', 'top center'], function (i, pos) {
                                                if (!iFits) {
                                                    inst.update('position', pos);
                                                    let fits = inViewport(inst.$datepicker[0]);
                                                    if (fits.all) {
                                                        iFits = true;
                                                    }
                                                }
                                            });
                                        }
                                    },
                                    onHide: function () {
                                        doc.find(".datepickers-container").removeClass("state--active");
                                    },
                                    onSelect: function (fd, d) {
                                        let month = $.fn.datepicker.language[lang]['months'][d.getMonth()];
                                        checkout.find("[data-day]").text(d.getDate());
                                        checkout.find("[data-month]").text(month);
                                    }
                                }).data('datepicker');

                                checkout.on("click", function () {
                                    datepicker_out.show();
                                });
                            });
                        });
                    });
                }
            })();

            selector.on("click", `[data-click^="number"]`, function (e) {
                e.preventDefault();
                let elm = $(this);
                let number = elm.closest(".elm_item_number").find("[data-number]");
                let inp = elm.closest(".elm_item_number").find("input");
                let num;

                if (elm.filter('[data-click="number-plus"]').length) {
                    num = +inp.val() + inp.data('step');
                    if (num <= inp.attr('max')) {
                        number.text(num);
                        inp.val(num).trigger("change");
                    }
                }
                if (elm.filter('[data-click="number-minus"]').length) {
                    num = +inp.val() - inp.data('step');
                    if (num >= inp.attr('min')) {
                        number.text(num);
                        inp.val(num).trigger("change");
                    }
                }
            });
        }
    });
});