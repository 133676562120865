$.lui("comp", ".comp_visual", function(selector) {
    let carousel = selector.find("[data-visual-slider]");
    let caption = selector.find('[data-visual-caption]');
    let autoplay = carousel.data("visual-slider");
    let bookingHeading = selector.find(`[data-ref="bookingHeading"]`);
    let bookingForm = selector.find(`[data-ref="bookingForm"]`);

    $.lib_flickity(function(){
        $.importScript(cdnjs.flickity_sync, function() {
            if(caption.children().length > 1) {
                caption.flickity({
                    contain: true,
                    pageDots: false,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: true
                });
            }

            if (carousel.length) {
                carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    autoPlay: autoplay,
                    pageDots: true,
                    wrapAround: false,
                    contain: true,
                    sync: '[data-visual-caption]',
                    prevNextButtons: true,
                    pauseAutoPlayOnHover: false
                });
            }

            let carousel_img = carousel.find('.elm_item .elm_item_image'),
                docStyle = document.documentElement.style,
                transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

            let flkty = carousel.data('flickity');

            carousel.on('scroll.flickity', function () {
                if (win.width() > 1024) {
                    flkty.slides.forEach(function (slide, i) {
                        let img = carousel_img[i],
                            x = (slide.target + flkty.x) * -1/2;

                        img.style[transformProp] = 'translateX(' + x + 'px)';
                    });
                }
            });
        });
    });

    bookingHeading.on("click",function (e) {
        e.preventDefault();
        if(win.width() < 961) {
            bookingForm.anchor_anim(500);
        }
    })
});